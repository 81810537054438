/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {
	/* global
----------------------------------------------------------------------------------------------*/
	.after_clear:after,
	.wrapper:after,
	section:after {
		clear: both;
		display: block;
		content: " ";
	}
	.before_clear:before {
		clear: both;
		display: block;
		content: " ";
	}

	.wrapper {
		width: auto;
	}
	.container {
		width: auto;
	}

	.ic_box {
		line-height: 30px;
		font-size: 19px;
		&.top,
		&.topCent {
			padding-top: 15px;
		}
		&.little:after {
		}
		&:after {
			width: 43px;
			background-size: 43px;
			height: 7px;
		}
	}

	/* header
----------------------------------------------------------------------------------------------*/
	header {
		padding: 0;
		.wrapper {
			margin: 0;
			margin-left: 20px;
		}
		.logo {
			width: 110px;
			margin-top: 12px;
		}
		.main_head {
			display: block;
		}
		.burger {
			display: block;
			position: relative;
			z-index: 99999;
		}
		&.home,
		&.float {
			padding: 0;
			@include boxSizing(border-box);
		}
		.nav {
			top: 58px;
			.top_mobile {
				padding: 15px 20px;
				.src_mob {
					width: 65%;
					form {
						input[type="text"] {
							font-size: 13px;
							padding: 10px 30px 10px 0;
							width: 100%;
						}
					}
				}
				.lang_mob {
					a {
						font-size: 13px;
					}
				}
			}
			ul {
				li {
					a {
						padding: 0 20px;
						font-size: 13px;
					}
					.arr_drop {
						width: 60px;
					}
					ul {
						li {
							a {
								padding-left: 40px;
							}
							ul {
								li {
									a {
										padding-left: 60px;
									}
								}
							}
						}
					}
				}
			}
		}
		.bg_white {
			height: 58px;
		}
		&.scroll-down {
			top: -58px;
		}
	}

	/* homepage
----------------------------------------------------------------------------------------------*/

	.sliderHome {
		padding-top: 58px;
		.slider {
			.caption {
				top: 85px;
				width: auto;
				padding: 0 12%;
				h1,h2 {
					margin-right: 0;
					font-size: 23px;
					width: auto;
					line-height: 34px;
					margin-bottom: 20px;
				}
			}
			.slick-arrow.slick-next:before {
				top: -25%;
			}
		}
		.ckb-group-stay {
			bottom: 12px;
			.wrapper {
				margin: 0 20px;
				padding: 16px;
				&:before {
					width: 80px;
					height: 64px;
				}
				h3 {
					font-size: 16px;
				}
				.link-icon {
					a {
						font-size: 11px;
						padding-right: 18px;
						margin-right: 18px;
						img {
							width: 20px;
							margin-right: 6px;
						}
					}
				}
			}
		}
	}
	.nav_slideHome {
		display: none;
	}
	.higlight {
		height: auto;
		.container {
			width: auto;
		}
		.left,
		.right {
			float: none;
			display: block;
			width: 100%;
			@include boxSizing(border-box);
			padding: 30px 20px;
		}
		.title_ico {
			.ic_box {
				padding-top: 0;
			}
		}
		.slideNews {
			margin-left: 200px;
			.news {
				a {
					p {
						height: 40px;
					}
				}
			}
		}
		.right {
			height: 100px;
			.drop_ship {
				top: 85px;
				width: 163px;
				bottom: auto;
				&:before {
					border-bottom: 10px solid #124936;
					border-top: none;
					bottom: auto;
					top: -9px;
				}
			}
			a {
				&.track {
					float: left;
				}
				&.booking {
					float: right;
					margin-top: 0;
				}
			}
		}
	}

	.homeAbout {
		padding: 40px 0;
		.wrapper {
			margin: 0 20px;
		}
		.txt_about {
			position: relative;
			top: 0;
			@include transform(translateY(0%));
			.rh_text {
				float: none;
				width: auto;
				padding-right: 0;
				h1 {
					font-size: 19px;
					line-height: 30px;
				}
				.videos {
					height: 245px;
					width: 440px;
					#vidAbout,
					.wrap_video {
						height: 245px;
						margin: 0 auto 25px;
						display: block;
					}
				}
			}
		}
		.img_about {
			display: none;
		}
	}
	.businessHome {
		.wrapper {
			margin: 0 20px;
		}
		.top_text {
			h1 {
				font-size: 19px;
				line-height: 30px;
			}
		}
	}

	.businessHome {
		background: #09543b url("../images/content/bg_business_home.jpg") no-repeat
			top center;
		padding-bottom: 100px;
		.ListBusiness {
			.item {
				width: 50%;
				.img {
					height: auto;
					img {
						width: 100%;
						left: 0;
					}
				}
				.capt {
					h5 {
						height: 32px;
					}
				}
			}
		}
	}

	.top_text {
		width: auto;
	}
	.homeNews {
		.wrapper {
			margin: 0 20px;
		}
		.top_text h1 {
			font-size: 19px;
			line-height: 30px;
		}
		.listnews {
			.item {
				float: none;
				width: 100%;
				margin: 0 0 25px;
				a {
					.dte {
						margin-bottom: 6px;
					}
					p {
						min-height: auto;
						height: auto;
					}
					h6 {
						min-height: 0;
					}
				}
			}
		}
	}

	.homeCareer {
		.wrapper {
			margin: 0 20px;
		}
		.img_career {
			width: 40%;
			display: none;
			img {
				object-position: left;
			}
		}
		.txt_career {
			width: auto;
			max-width: 100%;
		}
	}

	/* middle
----------------------------------------------------------------------------------------------*/
	.middle {
		padding: 58px 0 60px;
		.wrapper {
			margin: 0 20px;
			width: auto;
		}
	}
	.banner {
		.title_pg {
			h1,
			h2 {
				font-size: 19px;
			}
		}
	}
	.wrap_text {
		width: auto;
	}
	.over_wrap {
		margin: 0 0 30px;
	}
	.title_content {
		padding-top: 30px;
		h1 {
			padding-bottom: 15px;
			&:before {
				width: 60px;
			}
		}
	}

	.listing {
		&.listService {
			.item {
				width: auto;
				display: block;
				float: none;
				margin-right: 0;
				.text.v_center {
					width: auto;
					margin-left: 170px;
					float: none;
					padding-top: 15px;
				}
			}
		}
		&.subIntegrate {
			.item {
				.img {
					float: none;
					width: auto;
					margin-bottom: 20px;
					img {
						width: 100%;
					}
				}
				.text.v_center {
					position: relative;
					margin-left: 0;
					top: 0;
					@include transform(translateY(0%));
					width: auto;
					h3 {
						font-size: 16px;
					}
				}
			}
		}
		&.listIndustry {
			.item {
				width: 48%;
				margin-right: 4%;
				&:nth-of-type(3n) {
					margin-right: 4%;
				}
				&:nth-of-type(2n) {
					margin-right: 0;
				}
				.txt_hover {
					left: 12px;
					right: 12px;
					line-height: 18px;
					p {
						line-height: 18px;
					}
				}
			}
		}
		&.listWho,
		&.listCr {
			.item {
				width: 48%;
				margin-right: 4%;
				.img {
					img {
						display: block;
						width: 100%;
					}
				}
				&:nth-of-type(3n) {
					margin-right: 4%;
				}
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}
	}

	.sliderContent {
		.slick-arrow {
			width: 45px;
			height: 35px;
			&:after {
				width: 10px;
				background-size: 20px;
			}
			&.slick-prev {
				right: 45px;
			}
			&.slick-next {
				&:before {
					height: 20px;
				}
			}
		}
	}

	.about_sec {
		.text_about {
			.ic_box {
				font-size: 19px;
				padding-bottom: 15px;
				i {
					width: 70px;
				}
			}
		}
		.img_compli {
			display: none;
		}
		.img_respons {
			width: 30px;
			top: 0;
			bottom: 0;
			height: auto;
			.bg_green {
				width: 30px;
			}
			img {
				display: none;
			}
		}
		&.who {
			.img_who {
				float: none;
				width: auto;
				display: block;
				padding-top: 0;
				width: 570px;
				margin: 0 auto 30px;
				display: block;
			}
			.text_about {
				width: auto;
				float: none;
				.ic_box {
					font-size: 19px;
					padding-bottom: 15px;
				}
			}
		}
		&.compliance {
			.text_about {
				width: auto;
			}
		}
		&.respons {
			min-height: 0;
			.text_about {
				float: none;
				display: block;
				margin-left: 35px;
				width: auto;
			}
		}
		&.management {
			padding-top: 40px;
		}
	}

	.sliderGroup {
		.slide {
			margin: 0 10px;
		}
	}

	.listVisi {
		h3 {
			font-size: 16px;
			padding-top: 10px;
		}
		.list {
			.ico {
				margin-left: 10px;
			}
			.dec {
				margin-left: 70px;
				p {
					margin-left: -70px;
					&:nth-of-type(1) {
						margin-top: 40px;
					}
				}
				ul,
				ol {
					margin-left: -70px;
					&:nth-of-type(1) {
						margin-top: 40px;
					}
				}
			}
		}
	}
	.slideYear {
		margin-bottom: 40px;
		.year {
			height: 100px;
			h3 {
				font-size: 19px;
			}
			&.slick-center {
				h3 {
					font-size: 33px;
					padding-top: 15px;
				}
				&:before {
					height: 40px;
				}
			}
			&:before {
				height: 25px;
			}
		}
	}
	.slideText {
		width: 440px;
		.text_history {
			p {
				font-size: 13px;
				line-height: 20px;
			}
			ul {
				li {
					margin-bottom: 15px;
				}
			}
		}
	}

	.listAward {
		.list {
			width: 90%;
			margin-bottom: 50px;
			&:nth-child(2n) {
				margin-left: 10%;
			}
		}
	}

	.listFact {
		.item {
			float: none;
			display: block;
			width: auto;
			text-align: left;
			margin-bottom: 30px;
			margin-right: 0 !important;
			padding: 0;
			h2 {
				font-size: 29px;
			}
		}
	}

	.healthlist {
		.item {
			.ico {
				width: 70px;
				vertical-align: top;
			}
			.dec {
				padding-left: 15px;
				vertical-align: top;
			}
		}
	}

	.sec_bottom {
		padding-bottom: 50px;
		margin-bottom: -60px;
		.ic_box {
			font-size: 19px;
			line-height: 30px;
		}
		.slideRecommend {
			margin: 0 40px;
			.slick-list {
				padding: 0 10px;
			}
			.item {
				margin: 0 10px;
			}
			.slick-arrow {
				top: 30%;
				&.slick-prev {
					left: -40px;
				}
				&.slick-next {
					right: -40px;
				}
			}
		}
	}

	.gm-style {
		.gm-style-iw {
			width: 280px !important;
			margin-bottom: -2px;
			> div {
				width: 280px;
				max-width: none !important;
			}
		}
	}
	.map_info {
		width: 280px;
		@include boxSizing(border-box);
		.content_map {
			.right {
				display: none;
			}
		}
	}

	.slideCSR {
		.sliderCapt {
			position: relative;
			width: 100%;
			.capt {
				padding-top: 30px;
				h2 {
					width: 100%;
					font-size: 16px;
					line-height: 22px;
				}
			}
		}
		.slick-arrow {
			bottom: 0;
			width: 40px;
			&.slick-prev {
				right: 40px;
			}
			&.slick-next {
				&:before {
					height: 20px;
				}
			}
		}
	}
	.accordion {
		.title_acc {
			font-size: 16px;
			padding: 10px;
			h5 {
				font-size: 16px;
			}
			.ic_plus {
				width: 10px;
				height: 10px;
				&:before {
					height: 2px;
				}
				&:after {
					width: 2px;
				}
			}
		}
		.dec_acc {
			padding: 15px 10px;
		}
	}

	.sec_news {
		.img_news {
			display: none;
		}
		.text_news {
			float: none !important;
			display: block;
			width: auto !important;
			.ic_box {
				margin-bottom: 15px;
				&:before {
					width: 70px;
				}
			}
			.newslisting {
				.list {
					padding-bottom: 15px;
					margin-bottom: 15px;
					.dt {
						margin-bottom: 5px;
					}
				}
			}
		}
		&.indust {
			margin-bottom: -60px;
		}
	}
	.txt_filter {
		.left,
		.right {
			width: auto;
			display: block;
			width: 100%;
		}
	}
	.gridNews {
		.box {
			padding: 0 0 25px;
			margin-bottom: 30px;
			h3 {
				font-size: 16px;
				line-height: 22px;
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}

	.section_bottom {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		flex-flow: column;
		-webkit-flex-flow: column;
		-moz-flex-flow: column;
		-ms-flex-flow: column;
		-o-flex-flow: column;
		.pagging {
			float: none;
			order: 1;
			-webkit-order: 1;
			-moz-order: 1;
			margin-bottom: 30px;
			a {
				width: 15px;
				font-size: 16px;
				height: 25px;
				line-height: 25px;
				/*&:nth-of-type(2),
			&:nth-of-type(6) {display: none;}*/
				&.prev,
				&.next {
					width: 20px;
				}
			}
		}
		.topTop {
			float: none;
			order: 2;
			-webkit-order: 2;
			-moz-order: 2;
			margin: 0 auto;
		}
	}
	.pg_news {
		padding-top: 30px;
		> .ic_box {
			padding-bottom: 15px;
			&:before {
				width: 70px;
			}
		}
	}

	.pg_career {
		.img_carrer {
			position: relative;
			right: -0%;
			margin-bottom: 0;
			width: 350px;
		}
	}
	.sliderContent {
		&.capt {
			.slide {
				.captext {
					left: 20px;
					bottom: 20px;
					display: none;
					p {
						line-height: 18px;
					}
				}
			}
		}
	}

	.btm_contact {
		margin-bottom: -60px;
		.lf_contact,
		.rh_contact {
			float: none;
			display: block;
			width: auto;
			height: auto;
			padding: 40px 20px;
			.text_contact {
				position: relative;
				top: 0;
				@include transform(translateY(0));
				width: auto;
				float: none;
				display: block;
				left: 0;
				right: 0;
			}
		}
	}

	.map_ket {
		width: 400px;
		left: 0;
		right: 0;
		margin: auto;
		top: auto;
		@include transform(translateY(0%));
		bottom: 80px;
		.bx_btn {
			left: 30px;
			right: 30px;
		}
	}

	.nav_sub {
		&.office {
			margin-top: -43px;
			ul {
				position: relative;
				li {
					display: block;
					float: none;
					width: auto;
					border: none;
					a {
						height: auto;
						padding: 15px 10px;
						&:before {
							display: none;
						}
						&.active {
							background: #fff;
						}
					}
				}
			}
		}
	}

	.middle .txt_thanks {
		margin: 0 30px !important;
		text-align: center;
		.btn_box {
			span {
				line-height: 40px;
			}
			&:after {
				font-size: 11px;
				line-height: 40px;
			}
		}
	}
	.banner.thanks .txt_thanks h1 {
		width: auto;
		font-size: 23px;
		&:after {
			left: 0;
			right: 0;
			margin: auto;
		}
		&:before {
			left: 0;
			right: 0;
			margin: auto;
			width: 70px;
		}
	}
	.banner.thanks .txt_thanks p {
		font-size: 13px;
		line-height: 20px;
		width: 80%;
		margin: 0 auto 30px;
	}
	.banner.thanks .txt_thanks.txt_404 {
		top: 45%;
	}
	.banner.thanks .txt_thanks.txt_404 h1 {
		font-size: 80px;
	}
	.banner.thanks .txt_thanks.txt_404 p {
		margin-bottom: 20px;
	}

	.slideMangement {
		max-width: 500px;
		margin: 0 auto;
		&:before {
			display: none;
		}
		.slide {
			figure {
				float: none;
				display: block;
				margin: 0 auto 30px;
			}
			.txt {
				display: block;
				margin-left: 0;
				position: relative;
				top: 0;
				width: auto;
				max-width: 500px;
				margin: 0 auto;
				@include transform(translateY(0%));
				top: 0;
				h2 {
					&:after {
						left: -10px;
						width: 70px;
					}
				}
			}
		}
	}
	.nav_slideMagement {
		position: relative;
		top: 0;
		@include transform(translateY(0));
		margin: 20px 0 0 50px;
		.slick-arrow {
			width: 35px;
			height: 35px;
			display: inline-block;
			margin: 0 20px !important;
			vertical-align: top;
			@include transform(rotate(-90deg));
			&.slick-prev {
				svg {
					width: 35px !important;
					height: 35px !important;
				}
			}
		}
		&:before {
			left: 80px;
			right: auto;
			width: 2px;
			height: 50px;
		}
	}

	.slideTestimoni {
		width: auto;
		.box_img {
			float: none;
			display: block;
			margin: 0 auto;
			width: 280px;
		}
		.box_testimoni {
			float: none;
			display: block;
			width: auto;
			&:after {
				display: none;
			}
			.text {
				h2 {
					font-size: 16px;
					padding: 12px 82px 12px 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.isi {
				padding: 65px 20px 30px;
				.ket {
					span {
						display: block;
						margin: 0;
						border: none !important;
					}
				}
			}
			.slick-arrow {
				width: 40px;
				height: 40px;
				&.slick-prev {
					right: 40px;
				}
				&.slick-prev {
				}
			}
		}
	}

	.trigger-2 {
		top: 35%;
		bottom: auto;
	}
	.cus_say {
		.slider_customer {
			padding-right: 0;
			padding-bottom: 10px;
			&:before {
				display: none;
			}
			.slick-arrow {
				bottom: 0 !important;
				left: 0 !important;
				top: auto !important;
				right: auto;
				@include transform(rotate(-90deg));
				&.slick-next {
					left: 60px !important;
				}
			}
			.slide {
				.ket {
					p {
						display: block;
						margin-bottom: 10px;
						border: none;
					}
				}
				.img-customer {
					figcaption {
						padding: 0;
						.view-yt {
							position: relative;
							top: 0;
							transform: translateY(0);
							margin-top: 16px;
							font-size: 13px;
							right: 0;
							left: 0;
						}
					}
				}
			}
		}
		.container {
			margin: -50px 20px 40px;
		}
		.nav_slideCus {
			margin-top: 20px;
		}
	}

	.nav_sub {
		&.office {
			margin: 0 -20px;
			width: auto;
			.arr_drop {
				display: block;
				background: $c_yllow;
				padding: 15px;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				position: relative;
				&:after {
					width: 0;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid #fff;
					content: "";
					display: block;
					position: absolute;
					right: 20px;
					top: 20px;
				}
			}
			ul {
				border: 1px solid $c_yllow;
				display: none;
				li {
					a {
						text-align: left;
						padding: 15px;
					}
				}
			}
		}
	}

	.listManagent {
		margin: 0 20px;
		.item {
			.txt {
				h2 {
					font-size: 22px;
					line-height: 28px;
				}
			}
		}
	}

	.box_form .unit_satuan {
		margin-left: 0;
		margin-right: 0;
	}

  
  .box-wa {
    right: 20px; 
    a {
      font-size: 0;
      padding: 5px;
      height: 40px;
      line-height: 35px;
			padding: 0;
			width: 40px;
			img {
				width: 100%;
			}
      i {
        top: -5px;
        left: 5px;
        width: 20px !important;
        height: 20px !important;
      }
    }
  } 

  .full-section {
    figcaption {
      margin-top: -30px;
      .button-group {
        a {
          width: 160px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
        }
      }
    }
  }



	/* footer
----------------------------------------------------------------------------------------------*/
	footer {
		.top_footer {
			display: none;
		}
		.btm_footer {
			padding: 20px 0;
			height: auto;
			border-top: 1px solid #000;
			.wrapper {
				margin: 0 20px;
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				flex-flow: column;
				-webkit-flex-flow: column;
				-moz-flex-flow: column;
				-ms-flex-flow: column;
				-o-flex-flow: column;
			}
			.left {
				float: none;
				display: block;
				p {
					line-height: 20px;
					font-size: 13px;
					text-align: center;
					&.desc {
						font-size: 12px;
					}
				}
			}
			.right {
				display: block;
				text-align: center;
				top: 0;
				margin: 0;
				padding-top: 16px;
				.list_foot {
					margin: 0 0 10px;
					top: 0;
					right: 0;
				}
			}
		}
	}
	.homeAbout {
		.txt_about {
			.rh_text {
				h1,h2 {
					font-size: 20px;
					margin-bottom: 16px;
				}
			}
		}
	}
	.businessHome {
		background: none;
		padding: 20px 0;
		.wrapper {
			padding: 40px 24px;
		}
		.top_text {
			h1,h2 {
				font-size: 20px;
				margin-bottom: 16px;
			}
		}
	}
	.home-global-player {
		.wrapper {
			margin: 0 20px;
		}
		.desc-global {
			text-align: center;
			h3 {
				font-size: 20px;
				margin-bottom: 16px;
			}
			p {
				font-size: 14px;
			}
		}
    .img-global {
      width: 100%;
    }
	}
	.cus_say {
		padding: 32px 0 48px;
		h3 {
			font-size: 20px;
		}
		.wrapper {
			width: auto;
			margin: 0 20px;
		}
		.slider_customer {
			padding: 32px 24px 60px;
			.slide {
				.img-customer {
					flex-wrap: wrap;
					figure {
						margin: 0 auto 12px;
					}
					figcaption {
						width: 100%;
						padding-left: 0;
						text-align: center;
						h4 {
							font-size: 18px;
						}
					}
				}
				p {
					text-align: center;
					line-height: 160%;
				}
			}
			.slick-arrow {
				width: 40px;
				height: 40px;
				bottom: 16px !important;
				left: auto !important;
				transform: rotate(0) !important;
				&.slick-prev {
					right: 60px !important;
					transform: rotate(180deg) !important;
				}
				&.slick-next {
					left: auto !important;
					right: 20px !important;
				}
			}
		}
	}

} /* end media */

@media all and (max-width: 639px) {
	/* global
----------------------------------------------------------------------------------------------*/
	.after_clear:after,
	.wrapper:after,
	section:after {
		clear: both;
		display: block;
		content: " ";
	}
	.before_clear:before {
		clear: both;
		display: block;
		content: " ";
	}

	.wrapper {
		width: auto;
	}
	.container {
		width: auto;
	}

	/* header
----------------------------------------------------------------------------------------------*/
	header {
	}

	/* homepage
----------------------------------------------------------------------------------------------*/
	.sliderHome {
		.slider {
			.slick-arrow {
				width: 36px;
				height: 36px;
				right: 8px;
				&.slick-prev {
					left: 8px;
				}
			}
		}
	}


	/* middle
----------------------------------------------------------------------------------------------*/

	.listing {
		&.listIntegrated {
			.item {
				float: none;
				margin-right: 0 !important;
				width: 100%;
				text-align: left;
				display: table;
				.ico {
					margin: 0;
					display: table-cell;
					vertical-align: middle;
				}
				h6 {
					display: table-cell;
					vertical-align: middle;
					padding-left: 20px;
					font-size: 13px;
				}
			}
		}
		&.listIndustry {
			margin: 30px 0;
			.item {
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
				&:nth-of-type(3n) {
					margin-right: 0;
				}
				&:nth-of-type(2n) {
					margin-right: 0;
				}
				.txt_hover {
					left: 12px;
					right: 12px;
					line-height: 18px;
					p {
						line-height: 18px;
					}
				}
			}
		}
		&.listWho,
		&.listCr {
			.item {
				width: 100%;
				margin-right: 0;
				&:nth-of-type(3n) {
					margin-right: 0;
				}
				&:nth-of-type(2n) {
					margin-right: 0;
				}
				.dec {
					h6 {
						height: auto;
					}
					p {
						height: auto;
					}
				}
			}
		}
	}

	.sec_bottom {
		.slideRecommend {
			margin: 0 40px;
			.slick-list {
				padding: 0 5px;
			}
			.item {
				margin: 0 15px;
			}
			.slick-arrow {
				top: 28%;
			}
		}
	}

	.about_sec {
		&.who {
			.img_who {
				float: none;
				width: auto;
				display: block;
				padding-top: 0;
			}
		}
	}
	.sliderGroup {
		margin: 30px 50px 0;
		.slide {
			margin: 0 10px;
			.dec {
				h6 {
					height: auto;
				}
				p {
					height: auto;
				}
			}
		}
		.slick-arrow {
			&.slick-next {
				right: -50px;
			}
			&.slick-prev {
				left: -50px;
			}
		}
	}

	.listAward {
		.list {
			figure {
				float: none;
				display: block;
			}
			width: 100%;
			margin-bottom: 30px;
			&:nth-child(2n) {
				margin-left: 0;
			}
			.ket_award {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}

	.subCompliance {
		.box {
			width: 100%;
			padding: 0 !important;
			margin-bottom: 30px;
			&:before {
				display: none;
			}
		}
	}

	.content_Compliance {
		.tab_nav {
			.tab {
				width: 40%;
				margin: 0 2px;
				.ico {
					width: 100px;
				}
				&:nth-child(2) .ico {
					width: 110px;
				}
				h5 {
					font-size: 13px;
				}
				&:nth-child(1) {
					.ico {
						margin-bottom: 25px;
					}
				}
				&:after {
					width: 20px;
					height: 20px;
					border-width: 6px;
				}
			}
		}
		.box_white {
			padding: 30px 20px;
			.content_tab {
				> h3 {
					font-size: 16px;
					line-height: 22px;
				}
				ul li {
					line-height: 20px;
				}
			}
		}
		.slideSertifikat {
			margin-top: 30px;
			.slide {
				padding-bottom: 10px;
				figure {
					display: block;
					float: none;
				}
				.ket_award {
					display: block;
					margin-left: 0;
					float: none;
					margin-top: 20px;
					.by {
						line-height: 16px;
					}
				}
			}
			.slick-arrow {
				bottom: 0;
			}
		}
	}

	.healthlist {
		.item {
			float: none;
			display: block;
			width: auto !important;
			.ico {
				width: 70px;
				vertical-align: middle;
			}
			.dec {
				padding-left: 15px;
				vertical-align: middle;
			}
		}
	}

	.pg_career {
		padding: 120px 0 30px;
		.img_carrer {
			display: none;
		}
		.text_career {
			position: relative;
			top: 0;
			@include transform(translateY(0%));
			left: 20px;
			right: 20px;
			h3 {
				font-size: 19px;
				line-height: 26px;
			}
		}
	}

	.map_ket {
		width: 280px;
		padding: 35px 20px;
		.ic_box {
			margin-bottom: 15px;
		}
		.bx_btn {
			left: 15px;
			right: 15px;
			a {
				width: 85px;
				font-size: 16px;
				padding: 0 15px;
				text-align: center;
			}
		}
	}

	.box_form {
		.row {
			margin-bottom: 0;
			> label {
				margin-bottom: 30px;
				font-size: 15px;
			}
			> h3 {
				font-size: 18px;
				margin-bottom: 22px;
			}
			.col {
				float: none !important;
				width: auto;
				display: block;
				margin-right: 0;
				margin-bottom: 30px;
				.field {
					.lbel {
						font-size: 13px;
						top: 15px;
					}
					input[type="text"],
					input[type="email"],
					input[type="number"],
					select,
					textarea,
					input[type="password"] {
						font-size: 15px;
					}
					select {
						background-size: 12px;
					}
					&.focus {
						.lbel {
							font-size: 11px;
							overflow: hidden;
						}
					}
					.ck_radio,
					.ck_checkbox {
						font-size: 13px;
						padding-left: 25px;
					}
					.g-recaptcha {
						@include transform(scale(0.9));
						position: relative;
						left: -15px;
					}
				}
				.input_file span {
					font-size: 13px;
				}
			}
			&.change_rd {
				margin-bottom: 20px;
			}
		}
		.row.col-3_sat .col {
			width: 100%;
			margin-right: 0 !important;
		}
		.unit_satuan {
			padding-bottom: 5px;
		}
	}

	.listnetwork {
		.item {
			width: auto;
			display: block;
			float: none;
			margin-right: 0 !important;
			.images {
				display: none;
				img {
					width: 100%;
				}
			}
			.keterangan {
				padding: 15px 10px;
			}
		}
	}
	#map_office .gm-style .gm-style-iw {
		width: 280px !important;
	}
	.map_content {
		width: 280px;
	}

	.slideMangement {
		.slick-arrow {
			&.slick-prev {
				left: 40% !important;
				right: auto !important;
			}
			&.slick-next {
				right: 40% !important;
				left: auto !important;
			}
		}
	}

	.top_result {
		h3 {
			float: none;
			margin-bottom: 20px;
			font-size: 24px;
		}
		.src_result {
			float: none;
			form {
				input[type="text"] {
					width: 100%;
					font-size: 13px;
				}
			}
		}
		.notes {
			font-size: 14px;
		}
	}
	.list_result {
		.list {
			padding-bottom: 30px;
			margin-bottom: 30px;
			h3 {
				font-size: 16px;
			}
			p {
				font-size: 13px;
				line-height: 18px;
				margin-bottom: 20px;
			}
			a.continue {
				font-size: 12px;
				span {
					width: 12px;
					height: 12px;
				}
			}
		}
	}

	.healthlist .item p {
		font-size: 13px !important;
	}

	.listManagent {
		.item {
			float: none;
			width: auto;
			margin-right: 0;
			> a {
				display: table;
				position: relative;
				width: 100%;
				&:after {
					display: block;
					clear: both;
					content: "";
				}
			}
			figure {
				width: 115px;
				display: table-cell;
				img {
					width: 100%;
					display: block;
				}
			}
			.txt {
				position: relative;
				display: table-cell;
				width: auto;
				left: 0;
				vertical-align: middle;
				padding-left: 15px;
				bottom: 0;
				left: 0;
				h2 {
					font-size: 18px;
					line-height: 24px;
					color: #333;
				}
				h6 {
					color: #666;
					font-size: 12px;
				}
			}
			&:hover {
				.txt {
					left: 0;
					bottom: 0;
				}
			}
		}
	}

	.businessHome {
		.list-services {
			padding-top: 12px;
			.item-services {
				width: 100%;
				margin-right: 0;
				padding: 8px 0;
				&:nth-last-child(2) {
					border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
				}
			}
		}
	}
	.homeAbout {
		padding: 30px 0 20px;
		.videos {
			height: 260px;
			.wrap_video {
				height: 260px;
				.play_vid {
					width: 84px;
					height: 84px;
					background-size: 24px;
				}
			}
			#vidAbout {
				height: 260px;
			}
		}
	}

	/* footer
----------------------------------------------------------------------------------------------*/

	footer .btm_footer .left {
		/*ul li { margin-bottom: 10px; text-align: center;
		&:first-child { width: 100%; }
		&:nth-child(2):before { content: ''; }
	}*/
	}
} /* end media */

@media all and (max-width: 479px) {
	/* global
----------------------------------------------------------------------------------------------*/
	.after_clear:after,
	.wrapper:after,
	section:after {
		clear: both;
		display: block;
		content: " ";
	}
	.before_clear:before {
		clear: both;
		display: block;
		content: " ";
	}

	.wrapper {
		width: auto;
	}
	.container {
		width: auto;
	}

	/* header
----------------------------------------------------------------------------------------------*/
	header {
	}

	/* homepage
----------------------------------------------------------------------------------------------*/
	.sliderHome {
		.slider {
			.caption {
				h1,h2 {
					font-size: 20px;
					line-height: 30px;
				}
			}
			.images {
				video {
					height: calc(100vw * 1.5);
				}
			}
		}
	}
	.higlight {
		.title_ico {
			float: none;
			display: block;
			margin-bottom: 20px;
			.ic_box {
				width: auto;
			}
		}
		.right {
			height: auto;
			.drop_ship {
				top: auto;
				@include boxSizing(border-box);
				bottom: 95px;
				right: 20px;
				width: auto;
				left: 20px;
				&:before {
					border-top: 10px solid #124936;
					border-bottom: none;
					top: auto;
					bottom: -9px;
				}
			}
			a {
				&.track,
				&.booking {
					float: none;
					margin-top: 0;
					display: block;
					width: auto;
				}
				&.track {
					margin-bottom: 10px;
				}
			}
		}
		.slideNews {
			margin-left: 0;
		}
	}
	.homeAbout {
		.txt_about {
			.rh_text {
				.videos {
					height: 170px;
					width: 280px;
					#vidAbout,
					.wrap_video {
						height: 170px;
						margin: 0 auto 25px;
						display: block;
					}
					.wrap_video {
						overflow: hidden;
						img {
							position: relative;
							max-width: 320px;
							position: relative;
							left: -20px;
						}
					}
				}
			}
		}
		.img_about {
			display: none;
		}
	}

	.businessHome {
		.ListBusiness {
			.item {
				width: 280px;
				float: none;
				display: block;
				margin: 0 auto;
				.capt {
					h5 {
						height: auto;
					}
				}
			}
		}
	}

	.cus_say {
		.nav_slideCus {
			margin-left: 20px;
		}
	}

	/* middle
----------------------------------------------------------------------------------------------*/
	.nav_sub {
		width: auto;
		margin: 0 -20px;
		.sub_act span {
			width: auto;
			max-width: 210px;
		}
		.drop_sub {
			right: 0;
			left: 0;
			ul li a {
				width: auto;
				display: block;
			}
		}
	}
	.listing {
		.item {
			.ic_box {
				padding-top: 15px;
			}
		}
		&.listService {
			.item {
				.img {
					width: 100px;
					img {
						max-width: 100%;
						width: 100%;
						left: 0;
					}
				}
				.text.v_center {
					padding-top: 0;
					margin-left: 120px;
					h3 {
						min-height: 40px;
					}
					p {
						margin-left: -120px;
						margin-top: 30px;
					}
				}
			}
		}
	}

	.slideYear {
		margin: 0 15px 30px;
	}
	.slideText {
		width: auto;
	}

	.gridNews {
		.box {
			.shortdec {
				padding-left: 0;
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}

	.slideMangement {
		.slick-arrow {
			&.slick-prev {
				left: 35% !important;
				right: auto !important;
			}
			&.slick-next {
				right: 35% !important;
				left: auto !important;
			}
		}
	}

	.home-global-player {
		.desc-global {
			.ls-global {
				&__item {
					width: 100%;
					padding-bottom: 20px;
					margin-bottom: 20px;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						height: 1px;
						background: #ccc;
						opacity: .6;
						left: 0;
						right: 0;
						top: auto;
						bottom: 0;
						width: 100%;
						margin: auto;
					}
				}
			}
		}
	}

	/* footer
----------------------------------------------------------------------------------------------*/
} /* end media */

@media all and (max-width: 350px) {
	.gm-style .gm-style-iw + div {
		right: 10px !important;
	}
	.gm-style .gm-style-iw + div + img {
		right: 0px !important;
	}
}
